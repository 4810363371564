<template>
  <div>
    <div class="bg-body-light">
      <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
          <h1 class="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">Create an account</h1>
          <nav class="flex-sm-00-auto ml-sm-3" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">Entity</li>
              <li class="breadcrumb-item active" aria-current="page">Create</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'AccountCreate',
  data() {
    return {
      form: {}
    };
  },
  computed: {
    ...mapGetters({
      countries: 'util/countries',
      utilLoadingAction: 'util/loadingAction',
      loadingAction: 'account/loadingAction',
      account: 'account/account'
    })
  },
  watch: {
    account(accountData) {
      this.form = {
        ...this.form,
        ...accountData
      };
    }
  },
  created() {
    this.listCountries();
  },
  methods: {
    ...mapActions({
      listCountries: 'util/listCountries'
    })
  }
};
</script>
